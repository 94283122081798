import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

// Styles
import "./skodel-services.css";

// Components
import IndexLayout from "../layouts";
import Page from "../components/Page";
import StackedImage from "../components/StackedImage";
import FAQ from "../components/FAQ";
import BookDiscussion from "../components/BookDiscussion";

// Images and icons
import ServiceHero from "../assets/images/strategy-hero.jpg";
import ServiceCardBG from "../assets/images/leading-card-bg.png";
import IconWindowWhite from "../assets/icons/icon-window-white.svg";
import IconWindowDark from "../assets/icons/icon-window-dark.svg";
import IconBarWhite from "../assets/icons/icon-bar-white.svg";
import IconBarDark from "../assets/icons/icon-bar-dark.svg";
import IconMessageWhite from "../assets/icons/icon-message-white.svg";
import IconMessageDark from "../assets/icons/icon-message-dark.svg";
import IconGroupWhite from "../assets/icons/icon-group-white.svg";
import IconGroupDark from "../assets/icons/icon-group-dark.svg";
import StrategyDevelopKey from "../assets/images/strategy-develop-key.jpg";
import StrategyEffectiveTrack from "../assets/images/strategy-effective-track.png";
import StrategyCollaborate from "../assets/images/strategy-collaborate.jpg";
import StrategyCollaborateChart from "../assets/images/strategy-collaborate-chart.png";
import StrategyBestPractice from "../assets/images/strategy-best-practice.jpg";
import ServiceStackedImage from "../assets/images/strategy-wellbeing-safety.jpg";

// @ts-ignore
const NavLink = (props: any) => <Link activeClassName="active" {...props} />;

const ServicesValueData = [
  {
    id: "SV0001",
    imageWhite: IconWindowWhite,
    imageDark: IconWindowDark,
    title: "Increased silence on important issues",
    description:
      "Low organisational support encourages silence on mental health concerns. This means leaders have no visibility on important concerns that could be impacting performance or highlight systemic issues.",
  },
  {
    id: "SV0002",
    imageWhite: IconBarWhite,
    imageDark: IconBarDark,
    title: "Program ineffectiveness",
    description:
      "With limited support, leaders can't implement sustainable mental health initiatives. Programs go unused and are rotated in and out of organisations with minimal impact and a low ROI.",
  },
  {
    id: "SV0003",
    imageWhite: IconMessageWhite,
    imageDark: IconMessageDark,
    title: "Can't report on mental health",
    description:
      "If mental health isn't prioritised, people don't provide feedback on it. This makes it hard for leaders to make necessary adjustments, track impact and better understand factors impacting performance.",
  },
  {
    id: "SV0004",
    imageWhite: IconGroupWhite,
    imageDark: IconGroupDark,
    title: "Can't demonstrate commitment",
    description:
      "Limited support makes it hard to highlight commitment to mental health. This promotes the perception that it is neglected, which impacts reputation and hinders ability to attract those who prioritise mental health.",
  },
];

const FAQData = [
  {
    id: "FAQ0001",
    question: "Does Skodel Strategy cost extra?",
    answer:
      "As part of the onboarding process, Skodel collaborates with your leadership team on a 'key outcomes and implementation plan'. This comes at no extra cost. For additional services with one of our partners such as co-designing action plans, consultation on results and bi-annual reports this is extra and will be charged separately with our partner.",
  },
  {
    id: "FAQ0002",
    question: "How does it work and what do organisations get?",
    answer:
      "During the onboarding process, Skodel collaborates on a 'key outcomes and implementation plan' document with your leadership team. Based on this, Skodel can assist in setting up different check-ins, data dashboards and alerts to customise Skodel's platform to your needs.",
  },
  {
    id: "FAQ0003",
    question: "What support do you provide?",
    answer:
      "We offer email and phone support between business hours and we aim to respond within 2 business days to any request. We also offer an initial onboarding call to support effective setup and necessary training. For more detailed support, we have our Skodel Strategy package which includes reporting and strategy sessions with leadership.",
  },
];

const SkodelStrategy = () => {
  const [servicesValue, setServicesValue] = useState<
    {
      id: string;
      imageWhite: string;
      imageDark: string;
      title: string;
      description: string;
    }[]
  >([]);
  const [faq, setFAQ] = useState<
    { id: string; question: string; answer: string }[]
  >([]);

  useEffect(() => {
    setServicesValue(ServicesValueData);
    setFAQ(FAQData);
  }, [setServicesValue, setFAQ]);

  return (
    <IndexLayout withBlackLogo={true} workplacesHeader={false}>
      <Helmet>
        <title>Skodel</title>
        <meta
          property="og:title"
          content="Skodel - Report on psychological safety and support your people"
        />
        <meta
          property="og:description"
          content="Skodel's wellbeing and safety platform helps leaders with a higher duty of care meet requirements and support their people."
        />
      </Helmet>
      <Page>
        <section className="section-service-hero">
          <div
            className="section-bg"
            style={{ backgroundImage: `url('${ServiceHero}')` }}
          ></div>
          <div className="main-container">
            <div className="service-hero-content">
              <h1>Skodel Strategy</h1>
              <p>
                Collaborate and co-design your organisation's mental health
                safety strategy in line with international best practice and
                alongside industry experts.
              </p>
              <div className="service-hero-button">
                <NavLink
                  to={"/request-a-demo"}
                  className="btn btn-white btn-hero-cta"
                >
                  Book A Demo
                </NavLink>
                <a
                  href="https://skodel.notion.site/Skodel-example-success-plan-2f282f6b91ef41b78f66df02861b5208?pvs=4"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-white btn-hero-cta"
                >
                  Find Out More
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section-service-middle">
          <div className="main-container">
            <div className="service-content-wrapper">
              <div className="service-value">
                <div className="section-title text-center">
                  <h2>The problem</h2>
                  <p>
                    Organisations don't get widespread internal support on
                    mental health initiatives
                  </p>
                </div>
                <div className="service-value-grid column-4">
                  {servicesValue?.map((item, index) => (
                    <div className="service-card" key={item?.id + "-" + index}>
                      <img src={ServiceCardBG} alt={"Skodel"} />
                      <div className="service-icon">
                        <img src={item?.imageWhite} alt={"Skodel"} />
                        <img src={item?.imageDark} alt={"Skodel"} />
                      </div>
                      <div className="service-description">
                        <h3>{item?.title}</h3>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="service-zigzag-content">
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={StrategyDevelopKey} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Unite your organisation with a strategic plan</h3>
                    <p>
                      Skodel initiates its journey by facilitating a strategy
                      session aimed at collaboratively designing your
                      organisation's strategic plan. The session looks at
                      defining outcomes alongside actions to achieve these and
                      key metrics to track progress and gauge impact. This
                      process serves to unite leadership teams and the entire
                      organisation around shared mental health objectives and
                      the corresponding actions to achieve them. By fostering
                      collaboration and establishing a clearly defined strategy,
                      organisations enhance the long-term success of their
                      mental health programs.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={StrategyEffectiveTrack} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>
                      Effectively track and report on mental health to
                      stakeholders
                    </h3>
                    <p>
                      Reporting to key stakeholders on your organisation's
                      impact on mental health can help drive sustainable
                      engagement. The starting point to this is to develop a
                      reporting framework that aligns outcomes and actions with
                      the key metrics you will use to track progress. Skodel
                      collaborates with organisations to help them leverage
                      Skodel's tech solutions to design data collection and
                      reporting frameworks that illustrate the impact your
                      organisation is having and inform strategic action
                      planning.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper strategy-collaborate">
                  <div className="service-zigzag-image">
                    <img src={StrategyCollaborate} alt={"Skodel"} />
                    <div className="strategy-collaborate-chart">
                      <img src={StrategyCollaborateChart} alt={"Skodel"} />
                    </div>
                  </div>
                  <div className="service-zigzag-description">
                    <h3>
                      Collaborate, design and adjust strategic action plans
                    </h3>
                    <p>
                      Regular evaluation and adjustment of programs promotes a
                      culture of continuous improvement. By collecting feedback,
                      analysing data, and making necessary adjustments, leaders
                      can demonstrate their commitment to learning and adapting
                      to the evolving needs of their organisation. This
                      iterative process helps create more effective and
                      impactful mental health initiatives over time.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={StrategyBestPractice} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Align your strategy to best practice standards</h3>
                    <p>
                      Knowing the best approach to take in wellbeing and safety
                      can be challenging for leaders. Wellbeing and safety
                      standards, regulations and guidelines are growing while
                      your organisation has its own unique challenges. Leaders
                      can benefit from understanding what approaches other
                      organisations are taking, what regulations exist and how
                      international best practice guidelines are evolving.
                    </p>
                  </div>
                </div>
              </div>
              <div className="stacked-content">
                <StackedImage
                  image={ServiceStackedImage}
                  title={"Navigating wellbeing & safety"}
                  description={
                    "Developing a unified mental health strategy that has widespread support within an organisation can be a challenging task. But doing this well can transform aspirational planning into a clear and resolute commitment that has the capacity to change a culture. Skodel's onboarding flow is designed to help leaders first come together on common mental health goals and outcomes. Once this is defined, Skodel's software helps you achieve these outcomes through actions and track your progress on this journey, which drives widespread ongoing organisational support."
                  }
                />
              </div>
              <div className="service-faq-wrapper">
                <div className="section-title text-center">
                  <h2>Frequently asked questions</h2>
                </div>
                <div className="service-faq-content">
                  <div className="service-faq-list">
                    {faq?.map((item, index) => (
                      <FAQ
                        key={item?.id + "-" + index}
                        question={item?.question}
                        answer={item?.answer}
                      />
                    ))}
                  </div>
                  <BookDiscussion
                    title={"Book in a discussion"}
                    link={"/request-a-demo"}
                    button={"Request A Demo"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
    </IndexLayout>
  );
};

export default SkodelStrategy;
